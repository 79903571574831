import { lazy } from "react";

const PagesRoutes = [
  // Comercial
  {
    path: "/pages/Comercial",
    component: lazy(() => import("../../view/pages/comercial")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["comercial"],
  },
  {
    path: "/pages/Corretor",
    component: lazy(() => import("../../view/pages/comercial/Corretor")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["comercial"],
  },
  {
    path: "/comercial/painelcomercial",
    component: lazy(() => import("../../view/pages/comercial/PainelComercial")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["comercial"],
  },
  {
    path: "/pages/Produtores",
    component: lazy(() => import("../../view/pages/comercial/Produtor")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["comercial"],
  },
  {
    path: "/pages/Clientes",
    component: lazy(() => import("../../view/pages/comercial/Cliente")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["comercial"],
  },
  {
    path: "/pages/Contratos",
    component: lazy(() => import("../../view/pages/comercial/Contratos")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["comercial"],
  },


  //corretor
  {
    path: "/Corretor",
    component: lazy(() => import("../../view/pages/corretor/index")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["comercial"],
  },
  {
    path: "/Corretor/Lancar",
    component: lazy(() => import("../../view/pages/corretor/lancar")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["comercial"],
  },


  // Logistica
  {
    path: "/pages/Logistica",
    component: lazy(() => import("../../view/pages/logistica")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["logistica"]
  },



  // Fiscal
  {
    path: "/pages/fiscal",
    component: lazy(() => import("../../view/pages/fiscal")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["fiscal"]
  },




  // Frotas
  {
    path: "/pages/Frotas",
    component: lazy(() => import("../../view/pages/frotas")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["frotas"]
  },
  {
    path: "/frota/veiculos",
    component: lazy(() => import("../../view/pages/frotas/Veiculos")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["frotas"]
  },
  {
    path: "/frota/monitoramento",
    component: lazy(() => import("../../view/pages/frotas/Monitoramento")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["frotas"]
  },
  {
    path: "/frota/manutencao",
    component: lazy(() => import("../../view/pages/frotas/Manutencao")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["frotas"]
  },
  {
    path: "/frota/relatorios",
    component: lazy(() => import("../../view/pages/frotas/Relatorios")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["frotas"]
  },
  {
    path: "/frota/rastreamento",
    component: lazy(() => import("../../view/pages/frotas/Monitoramento")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["frotas"]
  },
  {
    path: "/frota/financas-frotas",
    component: lazy(() => import("../../view/pages/frotas/Financas")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["frotas"]
  },
  {
    path: "/frota/conjutos",
    component: lazy(() => import("../../view/pages/frotas/conjuto")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["frotas"]
  },
  {
    path: "/frota/licenca",
    component: lazy(() => import("../../view/pages/frotas/componetes/LicenseRegistrationPanel")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["frotas"]
  },
  {
    path: "/frota/pedagios",
    component: lazy(() => import("../../view/pages/frotas/pedagio")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["frotas"]
  },





  // Financeiro
  {
    path: "/pages/financeiro",
    component: lazy(() => import("../../view/pages/financeiro/index")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/pages/contas-a-receber",
    component: lazy(() => import("../../view/pages/financeiro/componetes/contasareceber")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/relatorios",
    component: lazy(() => import("../../view/pages/financeiro/relatorioPage")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/relatoriodetalhado",
    component: lazy(() => import("../../view/pages/financeiro/relatoriodetalhado")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/relatorio/abastecimento",
    component: lazy(() => import("../../view/pages/financeiro/relatorios/abastecimento")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/relatorio/conjuntos",
    component: lazy(() => import("../../view/pages/financeiro/relatorios/VehicleSetReportPanel")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/pages/autoriza",
    component: lazy(() => import("../../view/pages/financeiro/AutorizacaoPagamento")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["admin"]
  },

  {
    path: "/financeiro/contas",
    component: lazy(() => import("../../view/pages/financeiro/CriadorDespesas")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/contaBancaria",
    component: lazy(() => import("../../view/pages/financeiro/componetes/Banco")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/recorrente",
    component: lazy(() => import("../../view/pages/financeiro/painelrecorrente")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/painelDespesa",
    component: lazy(() => import("../../view/pages/financeiro/painelDespesa")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/contas-pagar",
    component: lazy(() => import("../../view/pages/financeiro/Contas-a-pagar")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/pages/Contas-a-Receber",
    component: lazy(() => import("../../view/pages/financeiro/Contas-a-Receber")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/pages/Cadastros-gerais",
    component: lazy(() => import("../../view/pages/financeiro/Cadastros-gerais")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/pages/Relatorios",
    component: lazy(() => import("../../view/pages/financeiro/Relatorios")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/pages/fatura",
    component: lazy(() => import("../../view/pages/financeiro/fatura")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },

  //despesas do financeiro

  {
    path: "/financeiro/combustiveis",
    component: lazy(() => import("../../view/pages/frotas/despesas/combustivel")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/postos",
    component: lazy(() => import("../../view/pages/frotas/despesas/postos")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/pedagios",
    component: lazy(() => import("../../view/pages/frotas/despesas/pedagio")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/rastreadores",
    component: lazy(() => import("../../view/pages/frotas/despesas/rastreador")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/ipva",
    component: lazy(() => import("../../view/pages/frotas/despesas/ipva")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/multas",
    component: lazy(() => import("../../view/pages/frotas/despesas/multasinf")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/estacionamentos",
    component: lazy(() => import("../../view/pages/frotas/despesas/estacionamento")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/seguros",
    component: lazy(() => import("../../view/pages/frotas/despesas/seguros")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },

  //despesas do financeiro



  {
    path: "/financeiro/consorcio",
    component: lazy(() => import("../../view/pages/financeiro/despesas/consorcio")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },

  {
    path: "/financeiro/financiamento",
    component: lazy(() => import("../../view/pages/financeiro/despesas/financiamento")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/capitaldegiro",
    component: lazy(() => import("../../view/pages/financeiro/despesas/capitaldegiro")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/CartaodeCredito",
    component: lazy(() => import("../../view/pages/financeiro/despesas/cartaodeCredito")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },

  {
    path: "/financeiro/salarios",
    component: lazy(() => import("../../view/pages/financeiro/despesas/salario")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },
  {
    path: "/financeiro/processamentos",
    component: lazy(() => import("../../view/pages/financeiro/processemantos")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["financeiro"]
  },


  // RH
  {
    path: "/pages/RecursosHumanos",
    component: lazy(() => import("../../view/pages/rh")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["rh"]
  },
  {
    path: "/rh/pessoas",
    component: lazy(() => import("../../view/pages/rh/pessoal")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["rh"]
  },
  {
    path: "/rh/function",
    component: lazy(() => import("../../view/pages/rh/FunctionRegistration")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["rh"]
  },
  {
    path: "/rh/empresas",
    component: lazy(() => import("../../view/pages/rh/empresas")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["rh"]
  },
  {
    path: "/rh/funcionarios",
    component: lazy(() => import("../../view/pages/rh/funcionario")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["rh"]
  },


  // Almoxarife
  {
    path: "/pages/Almoxarifado",
    component: lazy(() => import("../../view/pages/almoxarife")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["almoxarifado"]
  },


  {
    path: "/pages/motorista",
    component: lazy(() => import("../../view/pages/motoristas/")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["motorista"]
  },
  {
    path: "/pages/abastecimento",
    component: lazy(() => import("../../view/pages/motoristas/abastecimento")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["motorista"]
  },

  // ADM
  {
    path: "/pages/Administrativo",
    component: lazy(() => import("../../view/pages/adm")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["administrativo"]
  },



  // Eroo
  {
    path: "/pages/error-404",
    component: lazy(() => import("../../view/pages/errors/404")),
    layout: "FullLayout",
  },



  // Login

  {
    path: "/login",
    component: lazy(() => import("../../view/pages/authentication-modern/login")),
    layout: "FullLayout",
  },



  //Usuarios

  {
    path: "/personel-information",
    component: lazy(() => import("../../view/pages/profile/personel-information")),
    layout: "VerticalLayout",
    isProtected: true,
  },


];

export default PagesRoutes;