import { Award,Shop,Truck,Notepad2,Send ,WalletMoney,MobileProgramming,ReceiptText,ShoppingBag,HomeTrendUp} from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const pages = [
    {
        header: <IntlMessages id="Paginas" />,
        
    },
    {
        id: "Comercial",
        title: <IntlMessages id="Comercial" />,
        icon: <Shop size={18} />,
        navLink: "/pages/Comercial",
        permissions: ["comercial"],
        
    },
    {
        id: "Logistica",
        title: <IntlMessages id="Logistica" />,
        icon: <Send size={18} />,
        navLink: "/pages/Logistica",
        permissions: ["logistica"]
    },
    {
        id: "Fiscal",
        title: <IntlMessages id="Fiscal" />,
        icon: <ReceiptText size={18} />,
        navLink: "/pages/fiscal",
        permissions: ["fiscal"]
    },
    {
        id: "Frotas",
        title: <IntlMessages id="Frotas" />,
        icon: <Truck size={18} />,
        navLink: "/pages/Frotas",
        permissions: ["frotas"]
    },
    {
        id: "Financeiro",
        title: <IntlMessages id="Financeiro" />,
        icon: <WalletMoney size={18} />,
        permissions: ["financeiro"],
        navLink: "/pages/financeiro",
        
    },
    {
        id: "Recursos Humanos",
        title: <IntlMessages id="Recursos Humanos" />,
        icon: <MobileProgramming size={18} />,
        navLink: "/pages/RecursosHumanos",
        permissions: ["rh"]
    },
   
    {
        id: "Almoxarifado",
        title: <IntlMessages id="Almoxarifado" />,
        icon: <ShoppingBag size={18} />,
        navLink: "/pages/Almoxarifado",
        permissions: ["almoxarifado"]
    },
    {
        id: "motorista",
        title: <IntlMessages id="Motorista" />,
        icon: <Truck size={18} />,
        navLink: "/pages/motorista",
        permissions: ["motorista"]
    },
    {
        id: "Corretor",
        title: <IntlMessages id="Corretor" />,
        icon: <Shop size={18} />,
        navLink: "/Corretor",
        permissions: ["comercial"],
        
    },
    
    {
        id: "Administrativo",
        title: <IntlMessages id="Administrativo" />,
        icon: <HomeTrendUp size={18} />,
        navLink: "/pages/Administrativo",
        permissions: ["administrativo"]
    },
    
];

export default pages