import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from './languages';

import Router from "./router/Router";

export default function App() {
  // Redux
  
  return (
    <ConfigProvider>
      <IntlProvider >
        <Router />
      </IntlProvider>
    </ConfigProvider>
  );
}